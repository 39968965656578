import type { ExtendedAsyncAPISpec, ExtendedOpenAPISpec } from '@newdaycards/spec-tools';
import { Feedback } from 'components/feature/feedback';
import { LastUpdated } from 'components/feature/last-updated.tsx';
import type { PageProps } from 'gatsby';
import { useEffect } from 'react';
import { cn } from 'utils/cn.ts';
import { PageLayout } from '../layouts/page-layout';
import { ReferenceLayout } from '../layouts/reference';
import { isOverviewOrReferencePage } from '../layouts/root-layout.tsx';
import { DeprecationProvider } from '../providers/deprecation-provider';
import withAppInsights from '../utils/analytics/appinsights';

export interface SpecPageContext {
  slug: string;
  spec: ExtendedOpenAPISpec | ExtendedAsyncAPISpec;
  pagePath: string;
  title: string;
  description: string;
  deprecated: boolean;
}

export function ReferenceTemplate({ pageContext, location }: PageProps<null, SpecPageContext>) {
  const { title, description, pagePath, deprecated } = pageContext;

  useEffect(() => {
    if (!location.hash) return undefined;

    const timeout = setTimeout(() => {
      window.requestAnimationFrame(() => {
        const anchor = document.querySelector(location.hash.replace('/', ''));

        if (!anchor) return;

        const offset = anchor.getBoundingClientRect().top + window.scrollY;
        window.scroll({ top: offset - 20, left: 0 });
      });
    });

    return () => clearTimeout(timeout);
  });

  return (
    <DeprecationProvider>
      <PageLayout
        isReference
        location={location}
        isLargeContent
        title={title}
        description={description}
        commitDate={pageContext.spec?.['x-pub-settings'].commitDate || undefined}
      >
        <ReferenceLayout spec={pageContext.spec} pagePath={pagePath} deprecated={deprecated} />
        {isOverviewOrReferencePage(location.pathname) && (
          <div className={cn('w-full px-20 py-[30px] rounded-lg', 'bg-white dark:bg-dark-800 mt-10')}>
            <Feedback />
            {pageContext.spec?.['x-pub-settings'].commitDate && (
              <>
                <hr className="my-6 border-t border-neutral-300 opacity-60 dark:border-dark-500" />
                <LastUpdated commitDate={pageContext.spec?.['x-pub-settings'].commitDate} />
              </>
            )}
          </div>
        )}
      </PageLayout>
    </DeprecationProvider>
  );
}

export default withAppInsights(ReferenceTemplate);
